import React from 'react';

function Footer() {
    return (
        <div className="footer">
            <div className="ui container">
                <div className="footer-text">
                    <div className="ui borderless menu">
                        <div className="right menu">
                            <a href="/" className="ui borderless item">Home</a>
                            <a href="https://pershingsquarelaw.com/why-us/" className="ui borderless item">About Us</a>
                            <a href="https://pershingsquarelaw.com/why-us/#services-block_5f16005e3a06f" className="ui borderless item">Services</a>
                            <a href="https://pershingsquarelaw.com/people/" className="ui borderless item">Contact Us</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;

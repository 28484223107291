import React, { useState } from 'react';
import * as Analytics from '../common/analytics.js';
import API from '../common/api.js';
import { useRequestTextInputModal, useMessageModal, useErrorModal } from '../common/useModal.js';
import '../common/style.css';

export default function useRescheduleInterviewDialog({caseInfo}) {
    const [completed, setCompleted] = useState(false);
    const [failed, setFailed] = useState(false);

    const [openCompletedModal, completedModal] = useMessageModal({
        title: "Reschedule EDD Interview Call",
        msg: "We've received your request to reschedule the EDD interview. We'll be in touch soon.",
        action: () => {}
    });

    const [openFailedModal, failedModal] = useErrorModal({
        title: "Reschedule EDD Interview Call",
        msg: "Something went wrong sending your rescheduling request. Please retry.",
        requestRetry: () => {setFailed(false); openRescheduleInterviewModal();}
    });

    const [openRescheduleInterviewModal, rescheduleInterviewModal] = useRequestTextInputModal({
        title: "Reschedule EDD Interview Call",
        prompt: "Please state the reason for your request and a few times in which you are available:",
        okButtonText: "Send Request",
        action: async (text) => {
            Analytics.trackEvent("User", "Requested to reschedule", "EDD Interview");

            if (await API.sendRescheduleRequest(caseInfo.matter_id, "EDD Interview", text)) {
                openCompletedModal();
                setCompleted(true);
            } else {
                openFailedModal();
                setFailed(true);
            }
        }
    });

    function open() {
        setCompleted(false);
        setFailed(false);
        openRescheduleInterviewModal();
    }

    const modal = 
        completed ? completedModal :
        failed ? failedModal :
        rescheduleInterviewModal;

    return [open, modal];
}

'use strict';

export const CLIENT_PHONE_HEARING_NA = 2681584;
export const CLIENT_PHONE_HEARING_REQUESTED = 2681599;
export const CLIENT_PHONE_HEARING_APPROVED = 2681629;
export const CLIENT_PHONE_HEARING_DENIED = 2681614;
export const OPEN_CASE = "openCase";
export const RECENT_CASES = "recentCases";
export const UNREAD_HISTORY_COUNT = "unreadHistoryCount";
export const UNREAD_DOCUMENT_COUNT = "unreadDocumentCount";

export const TOKEN_UNKNOWN_STATUS = -1;
export const TOKEN_CANCELLED = 0;
export const TOKEN_SUCCESS = 1;
export const TOKEN_PIN_REQUIRED = 2;
export const TOKEN_UNKNOWN_CASE_ID = 3;
export const TOKEN_WRONG_PIN = 4;
import * as Constants from './constants.js';
var qs = require('qs');
// DIFFERENT FROM mobile app: import localStorage from 'react-native-simple-store';

// DIFFERENT FROM mobile app: 
// const productionBaseURL = "https://www.psl-app.com";
// const envBaseURL = process.env['PSL_API_SERVER'];
// console.log(`envBaseURL = ${envBaseURL}`);
// const baseURL = envBaseURL ? envBaseURL : productionBaseURL;
// console.log(`baseURL = ${baseURL}`);
const baseURL = "";

// DIFFERENT FROM mobile app: let caseTokens = [];
// DIFFERENT FROM mobile app: const CASE_TOKENS = "caseTokens";

export default class API {
    static async init() {
        // DIFFERENT FROM mobile app: const toks = await localStorage.get(CASE_TOKENS);
        // DIFFERENT FROM mobile app: caseTokens = JSON.parse(toks) || [];
    }

    static getCaseAuthorizationHeadersForCaseNumber(casenumber) {
        let headers = {};
        // DIFFERENT FROM mobile app: 
        // const token_info = API.getCachedCaseTokenByCaseID(casenumber);
        // if (token_info) {
        //     headers['Authorization'] = `CaseToken ${token_info.token}`;
        // }
        return headers;
    }

    static getCaseAuthorizationHeaders(matterID) {
        let headers = {};
        // DIFFERENT FROM mobile app: 
        // const token_info = API.getCachedCaseTokenByMatterID(matterID);
        // if (token_info) {
        //     headers['Authorization'] = `CaseToken ${token_info.token}`;
        // }
        return headers;
    }

    // Checks whether given case number is valid and if so, returns the meta data for it.
    // To distinguish refreshing a case that was already opened in the app by the user, versus a new open
    // set the is_open parameter.
    // Returns null on failure.
    static async getCaseInfo(casenumber, device, is_open) {
        const query = qs.stringify({"case_number": casenumber, "device": device, "is_open": is_open});
        const response = await fetch(`${baseURL}/matters?${query}`, {headers: this.getCaseAuthorizationHeadersForCaseNumber(casenumber)});
        if (response.status === 200) {
            const response_json = await response.json();
            return response_json;
        } else {
            console.log(`Unknown case number: ${casenumber}`)
            return null;
        }
    }

    // Creates a new document of the given type for the given matter on the server.
    // Returns the document ID on success, or null on failure. 
    static async createDocument(matterID, docType) {
        const query = qs.stringify({"matter_id": matterID, "doctype": docType});
        const response = await fetch(`${baseURL}/documents?${query}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                ...this.getCaseAuthorizationHeaders(matterID)
            }
        });
        if (response.status === 200) {
            const response_json = await response.json();
            return response_json.document_id;
        } else {
            console.log("Error occurred when trying to create document");
            console.log(response);
            return null;
        }
    }


    // Uploads an image to the given document
    // Returns true on success, false on failure
    static async uploadPage(documentID, img) {
        let contentType = "";
        if (img.endsWith(".jpg") || img.endsWith(".jpeg")) {
            contentType = "image/jpeg";
        } else if (img.endsWith(".png")) {
            contentType = "image/png";
        } else {
            console.log(`Error: unsupported image type, ${img}`);
            return false;
        }

        var data = new FormData();
        const filename = img.substring(img.lastIndexOf('/')+1)
        data.append('file', {
            uri: img,
            name: filename,
            type: contentType
        });

        console.log(`PUTting the image ${img} to the server`);
        const response = await fetch(`${baseURL}/documents/${documentID}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'multipart/form-data;'
            },
            body: data
        });
        if (response.status === 200) {
            console.log(`Uploaded ${img}`);
            return true;
        } else {
            console.log("Error occurred while uploading page");
            console.log(response);
            return false;
        }
    }


    // Upload notes to the given document
    // Returns true on success, false on failure
    static async uploadNotes(documentID, notes) {
        var data = new FormData();
        data.append('notes', notes);

        console.log("PUTting the notes to the server");
        const response = await fetch(`${baseURL}/documents/${documentID}`, {
            method: 'PUT',
            body: data
        });
        if (response.status === 200) {
            console.log("Uploaded notes");
            return true;
        } else {
            console.log("Error occurred while uploading notes");
            console.log(response);
            return false;
        }
    }


    // Kicks off the PDF conversion process
    // Return true on success, false on failure.
    static async convertToPDF(documentID) {
        const response = await fetch(`${baseURL}/documents/${documentID}/conversion`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
        if (response.status === 200) {
            console.log("Conversion to PDF initiated");
            return true;
        } else {
            console.log("Error occurred when trying to initiate PDF conversion process");
            console.log(response);
            return false;
        }
    }

    // Polls the PDF conversion status of the given document
    // Returns "converting", "storing" or "done" for valid documentIDs.
    // Returns null for unknown documentIDs.
    static async getConversionStatus(documentID) {
        const response = await fetch(`${baseURL}/documents/${documentID}/conversion`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
        if (response.status === 200) {
            const response_json = await response.json();
            console.log("Poll conversion status result:")
            console.log(response_json)
            return {status: response_json.status, clioDocumentID: response_json.clio_document_id};
        } else {
            console.log("Error occurred when trying to poll status of PDF conversion process");
            console.log(response);
            return null;
        }
    }


    // Updates contact details for given client
    // Returns true on success, false on failure
    static async updateContactInfo(clientID, info) {
        const query = qs.stringify({"street": info.street, "city": info.city, "province": info.province, "postal_code": info.postal_code, "phone": info.phone, "email": info.email});
        const response = await fetch(`${baseURL}/clients/${clientID}?${query}`, {
            method: 'PATCH',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
        if (response.status === 200) {
            return true;
        } else {
            console.log("Error occurred when trying to update contact information");
            console.log(response);
            return false;
        }
    }


    static async getCaseHistory(matterID) {
        const query = qs.stringify({"matter_id": matterID});
        const response = await fetch(`${baseURL}/matter_history?${query}`, {headers: this.getCaseAuthorizationHeaders(matterID)});
        if (response.status === 200) {
            const response_json = await response.json();
            return response_json;
        } else {
            console.log(`Unable to fetch case history for matter_id ${matterID}`)
            return [];
        }
    }


    static async sendRescheduleRequest(matterID, meetingName, notes) {
        const query = qs.stringify({"matter_id": matterID, "meeting_name": meetingName, "notes": notes});
        const response = await fetch(`${baseURL}/reschedule?${query}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                ...this.getCaseAuthorizationHeaders(matterID)
            }
        });
        if (response.status === 200) {
            return true;
        } else {
            console.log("Error occurred when trying to request reschedule");
            console.log(response);
            return false;
        }
    }

    static async sendHearingByPhoneRequest(matterID, notes) {
        const query = qs.stringify({"notes": notes});
        const response = await fetch(`${baseURL}/hearingbyphone/${matterID}?${query}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                ...this.getCaseAuthorizationHeaders(matterID)
            }
        });
        if (response.status === 200) {
            return true;
        } else {
            console.log("Error occurred when trying to request reschedule");
            console.log(response);
            return false;
        }
    }

    static async addNoteToMatter(matterID, subject, detail) {
        const query = qs.stringify({"matter_id": matterID, "subject": subject, "detail": detail});
        const response = await fetch(`${baseURL}/notes?${query}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                ...this.getCaseAuthorizationHeaders(matterID)
            }
        });
        if (response.status === 200) {
            return true;
        } else {
            console.log("Error occurred when trying to add a note to a case");
            console.log(response);
            return false;
        }
    }

    static async requestPushNotifications(matterID, token) {
        // POST the token to the backend server from where we will trigger push notifications
        const query = qs.stringify({"matter_id": matterID, "token": token});
        const response = await fetch(`${baseURL}/notification_token?${query}`, {
            method: 'POST',
            headers: this.getCaseAuthorizationHeaders(matterID)
        });
        if (response.status === 200) {
            console.log("Registered push notification token");
        } else {
            console.log("Error registering push notification token");
            console.log(response.status);
            console.log(response.body);
        }
    }

    static async getCaseNotes(matterID) {
        const query = qs.stringify({"matter_id": matterID});
        const response = await fetch(`${baseURL}/notes?${query}`, {headers: this.getCaseAuthorizationHeaders(matterID)});
        if (response.status === 200) {
            const response_json = await response.json();
            return response_json;
        } else {
            console.log(`Unable to notes for matter_id ${matterID}`)
            return [];
        }
    }

    // Returns true on success, false on failure
    // If you don't want to update either the subject or the detail, just pass in null for those parameters.
    static async updateNote(noteID, subject, detail) {
        const query = qs.stringify({"subject": subject, "detail": detail});
        const response = await fetch(`${baseURL}/notes/${noteID}?${query}`, {
            method: 'PATCH',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
        if (response.status === 200) {
            return true;
        } else {
            console.log("Error occurred when trying to update note");
            console.log(response);
            return false;
        }
    }

    static async dontHaveDocument(matter_id, docType, noteID, detail) {
        const query = qs.stringify({matter_id, docType, noteID, detail});
        const response = await fetch(`${baseURL}/donthavedoc?${query}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                ...this.getCaseAuthorizationHeaders(matter_id)
            }
        });
        if (response.status === 200) {
            return true;
        } else {
            console.log("Error occurred when trying to process dontHaveDocument");
            console.log(response);
            return false;
        }
    }

    static async sendHearingInfo(matter_id, year, month, day, time, location, judge) {
        const query = qs.stringify({year, month, day, time, location, judge});
        const response = await fetch(`${baseURL}/hearinginfo/${matter_id}?${query}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                ...this.getCaseAuthorizationHeaders(matter_id)
            }
        });
        if (response.status === 200) {
            return true;
        } else {
            console.log("Error occurred when trying to send hearing date info");
            console.log(response);
            return false;
        }        
    }

    static async getInboxDocuments(matterID) {
        const query = qs.stringify({"matter_id": matterID});
        const response = await fetch(`${baseURL}/inbox_documents?${query}`, {headers: this.getCaseAuthorizationHeaders(matterID)});
        if (response.status === 200) {
            const response_json = await response.json();
            return response_json;
        } else {
            console.log(`Unable to fetch inbox documents for matter_id ${matterID}`)
            return [];
        }        
    }

    static getDocumentURI(documentID) {
        const result = `${baseURL}/pdfviewer/viewer.html?doc=${documentID}`;
        console.log(result);
        return result;
    }

    static async getCaseToken(caseID, pin) {
        // returns an object of form {status, token, matterID};
        let headers = {};
        if (pin) {
            headers['Authorization'] = `CasePin ${pin.join("")}`;
        }
        const query = qs.stringify({"case_number": caseID});
        const response = await fetch(`${baseURL}/casetoken?${query}`, {headers});
        if (response.status === 200) {
            const response_json = await response.json();
            return response_json;
        } else {
            console.log(`Unknown error getting case token for ${caseID} using pin ${pin}`)
            return {status: Constants.TOKEN_UNKNOWN_STATUS, token: null, matterID: null};
        }
    }

    /* DIFFERENT FROM MOBILE
    static getCachedCaseTokenByCaseID(caseID) {
        // returns an object of form {caseID, matterID, token, requiresPin};
        for (let i = 0; i < caseTokens.length; ++i) {
            const token = caseTokens[i];
            if (token.caseID === caseID) {
                return token;
            }
        }
        return null;
    }

    static getCachedCaseTokenByMatterID(matterID) {
        // returns an object of form {caseID, matterID, token, requiresPin};
        for (let i = 0; i < caseTokens.length; ++i) {
            const token = caseTokens[i];
            if (token.matterID === matterID) {
                return token;
            }
        }
        return null;
    }

    static async cacheCaseToken(tokenInfo) {
        // tokenInfo is an object of form: {caseID, matterID, token, requiresPin}
        const otherCaseTokens = caseTokens.filter(token => (token.caseID !== tokenInfo.caseID) && (token.matterID !== tokenInfo.matterID)); // Make sure we don't have duplicates
        const newCaseTokens = [...otherCaseTokens, tokenInfo];
        await localStorage.save(CASE_TOKENS, JSON.stringify(newCaseTokens));
        caseTokens = newCaseTokens;
    }

    static async checkCaseTokenValid(caseID, token) {
        // returns true or false
        const query = qs.stringify({"case_number": caseID});
        const response = await fetch(`${baseURL}/casetoken?${query}`, {headers: this.getCaseAuthorizationHeadersForCaseNumber(caseID)});
        if (response.status === 200) {
            return true;
        } else {
            return false;
        }
    }
    */

    static async remindCasePinSMS(caseID) {
        const query = qs.stringify({"case_number": caseID, "kind": "sms"});
        const response = await fetch(`${baseURL}/remindcasepin?${query}`, {method: 'POST'});
        if (response.status === 200) {
            return true;
        } else {
            return false;
        }
    }

    static async remindCasePinEmail(caseID) {
        const query = qs.stringify({"case_number": caseID, "kind": "email"});
        const response = await fetch(`${baseURL}/remindcasepin?${query}`, {method: 'POST'});
        if (response.status === 200) {
            return true;
        } else {
            return false;
        }
    }

    // EXTRA FOR WEB
    static generateICS(event_start, event_end, title, location) {
        const query = qs.stringify({event_start, event_end, title, location});
        return `${baseURL}/ics?${query}`;
    }

    // EXTRA FOR WEB
    // Uploads a PDF to the given document
    // Returns true on success, false on failure
    static async uploadPDF(documentID, file) {
        console.log(file)
        var data = new FormData();
        data.append('file', file);

        console.log(`PUTting the PDF ${file.name} to the server`);
        const response = await fetch(`${baseURL}/documents/${documentID}`, {
            method: 'PUT',
            body: data
        });
        if (response.status === 200) {
            console.log(`Uploaded ${file.name}`);
            return true;
        } else {
            console.log("Error occurred while uploading PDF file");
            console.log(response);
            return false;
        }
    }

    // EXTRA FOR WEB
    static async getDocumentInfo(matterID, documentID) {
        const response = await fetch(`${baseURL}/inbox_documents/${documentID}?matter_id=${matterID}`, {headers: this.getCaseAuthorizationHeaders(matterID)});
        if (response.status === 200) {
            const response_json = await response.json();
            return response_json;
        } else {
            console.log(`Unable to fetch document info for ${documentID}`)
            return null;
        }
    }
}


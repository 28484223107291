import React, { useState, useEffect, useRef, useCallback } from "react";
import Home from "./pages/home.js";
import Case from "./pages/case.js";
import EditContactInformation from "./pages/editcontactinformation.js";
import UploadDocument from "./pages/uploaddocument.js";
import ViewDocument from "./pages/viewdocument.js";
import * as Analytics from "./common/analytics.js";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Navigation from "./components/navigation.js";
import * as Constants from "./common/webconstants.js";
import API from "./common/api.js";
import Footer from "./components/footer.js";
var qs = require("qs");
var moment = require("moment");
require("./common/moment-timezone-with-data.js");

function App() {
    const [activeTab, setActiveTab] = useState("");
    const [caseNumber, setCaseNumber] = useState(null);
    const [loading, setLoading] = useState(true);
    const [refreshing, setRefreshing] = useState(false);
    const [errMsg, setErrMsg] = useState(null);
    const [caseInfo, setCaseInfo] = useState(null);
    const [caseHistory, setCaseHistory] = useState(null);
    const [caseDocuments, setCaseDocuments] = useState(null);
    const [, setCaseNotes] = useState(null);
    const [requireDocumentRequests, setRequireDocumentRequests] = useState(null);
    const intervalRef = useRef({});

    const fetchMatter = useCallback(async () => {
        if (caseNumber) {
            console.log(`Loading ${caseNumber}`);
            setRefreshing(!loading);
            const data = await API.getCaseInfo(caseNumber, window.navigator.userAgent, true);
            if (data) {
                const [history, documents, notes] = await Promise.all([
                    API.getCaseHistory(data.matter_id),
                    API.getInboxDocuments(data.matter_id),
                    API.getCaseNotes(data.matter_id),
                ]);
                setLoading(false);
                setRefreshing(false);
                setCaseInfo(data);
                setCaseHistory(history);
                setCaseDocuments(documents);
                setCaseNotes(notes);

                const reqs = notes
                    .filter((note) => note.subject.includes("[REQUIRE_DOC]"))
                    .map((note) => ({
                        id: note.note_id,
                        hardRequirement: true,
                        docName: note.subject.replace("[REQUIRE_DOC]", "").trim(),
                    }));

                const reminds = notes
                    .filter((note) => note.subject.includes("[REMIND_DOC]"))
                    .map((note) => ({
                        id: note.note_id,
                        hardRequirement: false,
                        docName: note.subject.replace("[REMIND_DOC]", "").trim(),
                    }));

                setRequireDocumentRequests(reqs.concat(reminds));

                console.log(`Loaded ${caseNumber}`);
            } else {
                setErrMsg("Error: cannot load your data, because your session expired. You need to log in again.");
                setLoading(false);
            }
        }
    }, [caseNumber, loading]);

    useEffect(() => {
        if (caseNumber) {
            fetchMatter();
        }
    }, [caseNumber]); // Intentionally omitted fetchMatter from dependency list to avoid needless refetching

    async function requestRefreshCase() {
        if (caseNumber) {
            await fetchMatter();
        }
    }

    async function dontHaveDocumentPressed(req) {
        Analytics.trackEvent("User", "Don't have required document", req.docName);
        const noteID = req.id;
        const subject = `Requested ${req.docName}`;
        const detail = `Client reported he does not have a ${req.docName} document on ${moment().format(
            "YYYY-MM-DD HH:mm"
        )}.`;
        const status = await API.updateNote(noteID, subject, detail);
        if (status) {
            await API.dontHaveDocument(caseInfo.matter_id, req.docName, noteID, detail);
            await requestRefreshCase();
        } else {
            console.log("Error: something went wrong updating the note on the server...");
            alert("Something went wrong while communicating with the server. Please retry.");
        }
    }

    useEffect(() => {
        intervalRef.current = setInterval(() => {
            if (caseNumber) {
                fetchMatter();
            }
        }, 60 * 1000);
        return function clear() {
            clearInterval(intervalRef.current);
        };
    }, [fetchMatter, caseNumber]);

    let requireDocumentRequester = null;
    if (caseNumber && requireDocumentRequests && requireDocumentRequests.length > 0) {
        const req = requireDocumentRequests[0];
        const urlParams = qs.parse(window.location.search, { ignoreQueryPrefix: true });
        if (urlParams.requestID) {
            requireDocumentRequester = (
                <div className="ui container">
                    <div className="ui info icon message">
                        <i className="inbox icon"></i>
                        <div className="content">Please upload the {req.docName} document.</div>
                    </div>
                </div>
            );
        } else {
            const message = req.hardRequirement
                ? `We need the "${req.docName}" document in order to continue working on your case. We cannot make progress on your case until you send us the required documents.`
                : `Do you have the "${req.docName}" document? If so, please upload it to us.`;
            requireDocumentRequester = (
                <div className="ui container">
                    <div className="ui red icon message">
                        <i className="inbox icon"></i>
                        <div className="content">
                            {message}
                            <div className="mt-4">
                                <Link
                                    to={`/case/${caseInfo.case_number}/upload?requestID=${req.id}`}
                                    className="ui primary labeled icon button"
                                >
                                    <i className="plus icon" />
                                    Upload {req.docName} Document
                                </Link>
                                <button className="ui basic black button" onClick={() => dontHaveDocumentPressed(req)}>
                                    I Don't Have This Document
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }

    return (
        <div style={{ display: "flex", minHeight: "100vh", flex: 1, flexDirection: "column" }}>
            <Router>
                {caseNumber && <Navigation caseNumber={caseNumber} activeTab={activeTab} />}
                {requireDocumentRequester}
                <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                    <Switch>
                        <Route
                            exact={true}
                            path="/case/:caseNumber"
                            render={({ match }) => {
                                setCaseNumber(match.params.caseNumber);
                                setActiveTab(Constants.DETAILS_TAB);
                                return (
                                    <Case
                                        activeTab={Constants.DETAILS_TAB}
                                        loading={loading}
                                        refreshing={refreshing}
                                        caseNumber={caseNumber}
                                        caseInfo={caseInfo}
                                        caseHistory={caseHistory}
                                        caseDocuments={caseDocuments}
                                        errMsg={errMsg}
                                        requestRefreshCase={requestRefreshCase}
                                    />
                                );
                            }}
                        />
                        <Route
                            path="/case/:caseNumber/details"
                            render={({ match }) => {
                                setCaseNumber(match.params.caseNumber);
                                setActiveTab(Constants.DETAILS_TAB);
                                return (
                                    <Case
                                        activeTab={Constants.DETAILS_TAB}
                                        loading={loading}
                                        refreshing={refreshing}
                                        caseNumber={caseNumber}
                                        caseInfo={caseInfo}
                                        caseHistory={caseHistory}
                                        caseDocuments={caseDocuments}
                                        errMsg={errMsg}
                                        requestRefreshCase={requestRefreshCase}
                                    />
                                );
                            }}
                        />
                        <Route
                            path="/case/:caseNumber/status"
                            render={({ match }) => {
                                setCaseNumber(match.params.caseNumber);
                                setActiveTab(Constants.STATUS_TAB);
                                return (
                                    <Case
                                        activeTab={Constants.STATUS_TAB}
                                        loading={loading}
                                        refreshing={refreshing}
                                        caseNumber={caseNumber}
                                        caseInfo={caseInfo}
                                        caseHistory={caseHistory}
                                        caseDocuments={caseDocuments}
                                        errMsg={errMsg}
                                        requestRefreshCase={requestRefreshCase}
                                    />
                                );
                            }}
                        />
                        <Route
                            path="/case/:caseNumber/documents"
                            render={({ match }) => {
                                setCaseNumber(match.params.caseNumber);
                                setActiveTab(Constants.DOCUMENTS_TAB);
                                return (
                                    <Case
                                        activeTab={Constants.DOCUMENTS_TAB}
                                        loading={loading}
                                        refreshing={refreshing}
                                        caseNumber={caseNumber}
                                        caseInfo={caseInfo}
                                        caseHistory={caseHistory}
                                        caseDocuments={caseDocuments}
                                        errMsg={errMsg}
                                        requestRefreshCase={requestRefreshCase}
                                    />
                                );
                            }}
                        />

                        <Route
                            path="/case/:caseNumber/editcontactinformation"
                            render={({ match }) => {
                                setCaseNumber(match.params.caseNumber);
                                setActiveTab("");
                                return (
                                    <EditContactInformation
                                        loading={loading}
                                        caseNumber={caseNumber}
                                        caseInfo={caseInfo}
                                        requestRefreshCase={requestRefreshCase}
                                    />
                                );
                            }}
                        />

                        <Route
                            path="/case/:caseNumber/upload"
                            render={({ match }) => {
                                setCaseNumber(match.params.caseNumber);
                                setActiveTab("");
                                let req = null;
                                if (requireDocumentRequests && requireDocumentRequests.length > 0) {
                                    const urlParams = qs.parse(window.location.search, { ignoreQueryPrefix: true });
                                    if (urlParams.requestID) {
                                        req = requireDocumentRequests.find(
                                            (r) => `${r.id}` === `${urlParams.requestID}`
                                        );
                                    }
                                }
                                return (
                                    <UploadDocument
                                        loading={loading}
                                        caseInfo={caseInfo}
                                        requestRefreshCase={requestRefreshCase}
                                        request={req}
                                    />
                                );
                            }}
                        />

                        <Route
                            path="/case/:caseNumber/document/:documentID"
                            render={({ match }) => {
                                setCaseNumber(match.params.caseNumber);
                                setActiveTab(Constants.DOCUMENTS_TAB);
                                return (
                                    <ViewDocument
                                        caseNumber={caseNumber}
                                        documentID={match.params.documentID}
                                        caseInfo={caseInfo}
                                    />
                                );
                            }}
                        />

                        <Route
                            exact={true}
                            path="/"
                            render={() => {
                                setCaseNumber(null);
                                setActiveTab("");
                                return <Home />;
                            }}
                        />
                    </Switch>
                </div>
            </Router>
            <Footer />
        </div>
    );
}

export default App;

import React, { useState } from 'react';
import * as Analytics from '../common/analytics.js';
import API from '../common/api.js';
import { useRequestTextInputModal, useYesNoModal, useMessageModal, useErrorModal } from '../common/useModal.js';
import '../common/style.css';


export default function useRescheduleHearingDialog({caseInfo}) {
    const [step, setStep] = useState(1);
    const [completed, setCompleted] = useState(false);
    const [failed, setFailed] = useState(false);

    const [openCompletedModal, completedModal] = useMessageModal({
        title: "Reschedule Hearing Meeting",
        msg: "We've received your request to reschedule the hearing. We'll be in touch soon.",
        action: () => {}
    });

    const [openFailedModal, failedModal] = useErrorModal({
        title: "Reschedule Hearing Meeting",
        msg: "Something went wrong sending your rescheduling request. Please retry.",
        requestRetry: () => {setFailed(false); setStep(1); openRescheduleHearingModal();}
    });

    const [openRescheduleHearingModal2, rescheduleHearingModal2] = useRequestTextInputModal({
        title: "Reschedule Hearing Meeting",
        prompt: "Your request to attend the hearing by phone must be approved by the judge. Please explain why you cannot appear in person:",
        okButtonText: "Send Request",
        action: async (text) => {
            Analytics.trackEvent("User", "Requested to attend hearing by phone", "Hearing");

            if (await API.sendHearingByPhoneRequest(caseInfo.matter_id, text)) {
                openCompletedModal();
                setCompleted(true);
            } else {
                openFailedModal();
                setFailed(true);
            }
        }
    });

    const [openRescheduleHearingModal3, rescheduleHearingModal3] = useRequestTextInputModal({
        title: "Reschedule Hearing Meeting",
        prompt: "Hearings can only be re-opened for good cause at the judge’s discretion. If you cannot appear in person or by phone, please explain why and provide other availability:",
        okButtonText: "Send Request",
        action: async (text) => {
            Analytics.trackEvent("User", "Requested to reschedule", "Hearing");

            if (await API.sendRescheduleRequest(caseInfo.matter_id, "hearing", text)) {
                openCompletedModal();
                setCompleted(true);
            } else {
                openFailedModal();
                setFailed(true);
            }
        }
    });

    const [openRescheduleHearingModal, rescheduleHearingModal] = useYesNoModal({
        title: "Reschedule Hearing",
        prompt: "Are you able to attend by phone at the scheduled time and date?",
        yesAction: () => {openRescheduleHearingModal2(); setStep(2);},
        noAction: () => {openRescheduleHearingModal3(); setStep(3);}
    });

    const modal = 
        completed ? completedModal :
        failed ? failedModal :
        (step === 1) ? rescheduleHearingModal :
        (step === 2) ? rescheduleHearingModal2 :
        rescheduleHearingModal3;

    function open() {
        setStep(1);
        setCompleted(false);
        setFailed(false);
        openRescheduleHearingModal();
    }

    return [open, modal];
}
import React, { useState, useEffect } from 'react';
import API from '../common/api.js';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css'
import { findDocSample } from '../common/docsamples.js';
var classNames = require('classnames');
var moment = require('moment');
require("../common/moment-timezone-with-data.js");


function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

function UploadDocument({loading, requestRefreshCase, caseInfo, request}) {
    const [docType, setDocType] = useState(null);
    const [file, setFile] = useState(null);
    const [notes, setNotes] = useState(null);
    const [busy, setBusy] = useState(false);
    const [errMsg, setErrMsg] = useState(null);

    async function upload() {
        setBusy(true);
        const documentID = await API.createDocument(caseInfo.matter_id, docType.value);
        const success = await API.uploadPDF(documentID, file);
        if (success) {
            if (notes && notes.length > 0) {
                await API.uploadNotes(documentID, notes);
            }
            const conversionStarted = await API.convertToPDF(documentID); // In our case, not really a conversion since it's already a PDF. But this kicks off storing on Clio servers.
            if (conversionStarted) {  
                while (true) {
                    const conversion = await API.getConversionStatus(documentID);
                    if (conversion === null) {
                        setErrMsg("Failed to store file. Please retry.");
                        break;
                    } else if (conversion.status === "done") {
                        if (request) {
                            const subject = `Requested ${docType}`;
                            const detail = `Client uploaded the ${docType} document on ${moment().format("YYYY-MM-DD HH:mm")}.`;
                            await API.updateNote(request.id, subject, detail);
                        }
                        await requestRefreshCase();
                        window.history.back();
                        break;
                    }
                    await timeout(500); // ms
                }
                
            } else {
                setErrMsg("Failed to store file. Please retry.");
            }
        } else {
            setErrMsg("Failed to upload file. Please retry.");
        }
        setBusy(false);
    }

    const errMsgEl = errMsg !== null && (
        <div className="ui visible error message">
            <p>{errMsg}</p>
        </div>
    );

    useEffect(() => {
        if (request) {
            setDocType({value: request.docName, label: request.docName});
        }
    }, [request])

    const possibleDocTypes = [
        "Unemployment Notice of Determination",
        "Disability Notice of Determination",
        "Unemployment Notice of Overpayment",
        "Disability Notice of Overpayment",
        "Other EDD Document",
        "Notice of Hearing",
        "Notice of Benefits Continuation Form",
        "Appeal Hearing Decision",
        "Board Appeal Decision",
        "Acknowledgement of Letter",
        "Appeal Acknowledgement",
        "Work Related Document",
        "Health Related Document",
        "Other"
    ];

    let sample = <img src={require("../assets/samples/placeholder_null.png")} alt="sample" style={{width: '100%'}}/>;
    if (docType) {
        let sampleInfo = findDocSample(docType.value);
        if (!sampleInfo) {
            if (caseInfo && caseInfo.practice_area === "Unemployment Benefits") {
                sampleInfo = findDocSample(`Unemployment ${docType.value}`);
            } else if (caseInfo && caseInfo.practice_area === "Disability Benefits") {
                sampleInfo = findDocSample(`Disability ${docType.value}`);
            }
        }
        if (sampleInfo) {
            sample = <img src={sampleInfo.img} alt="sample" style={{width: '100%'}}/>
        }
    }
    
    let content = null;
    if (busy) {
        content = <div className="ui active inline text loader">Uploading</div>;
    } else if (loading) {
        content = <div className="ui active inline text loader">Loading</div>;
    } else {
        content = (
            <div className="content">
                <div className="description">
                    <div className="ui equal width grid">
                        <div className="column">
                            <form className="ui form">
                                <div className="field">
                                    <label>Document Type</label>
                                    <Dropdown options={possibleDocTypes} onChange={(option) => setDocType(option)} value={docType} placeholder="Select" disabled={request !== null}/>
                                </div>
                                <div className="field">
                                    <label>File to upload</label>
                                    <input type="file" accept="application/pdf" onChange={(val) => setFile(val.target.files[0])}/>
                                </div>
                                <div className="field">
                                    <label>Type any notes you want to add below:</label>
                                    <textarea placeholder="Enter notes (optional)..." lines={6} onChange={(ev) => setNotes(ev.target.value)}></textarea>
                                </div>
                                <div className={classNames("ui primary right labeled fluid icon button", {loading: busy})} onClick={upload}>Upload</div>
                                {errMsgEl}
                            </form>
                        </div>
                        <div className="column">
                            <div className="ui warning message">
                                <div className="content">
                                    <div className="header">Important</div>
                                    <p>Please make sure you select the correct document type. If you're not sure, view the sample below.</p>
                                    {sample}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="ui container">
            <div className="ui hidden divider"></div>
            <div className="ui raised segment">
                <h1 className="ui header">Upload New Document</h1>
                {content}
            </div>
            <div className="ui hidden divider"></div>
        </div>
  );
}
  
export default UploadDocument;
  
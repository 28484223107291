'use strict';

var moment = require('moment');
require("../common/moment-timezone-with-data.js");
import * as Constants from '../common/constants.js';

export function interpretHearingInfo(caseInfo) {
    if (caseInfo.hearing_fsm_state == "cancelled") {
        return {hearing_date: "Cancelled", hearing_time: "", hearing_location: "", have_future_trial_date: false, have_trial_date: false};
    } else if (caseInfo.hearing_fsm_state == "withdrawn") {
        return {hearing_date: "Withdrawn", hearing_time: "", hearing_location: "", have_future_trial_date: false, have_trial_date: false};
    } else if ((caseInfo.hearing_fsm_state == "self_represented") && (caseInfo.obsolete_trial_date)) {
        const td = moment(caseInfo.obsolete_trial_date);
        return {
            hearing_date: td.tz('America/Los_Angeles').format("MMM D, YYYY"), 
            hearing_time: ("at " + td.tz('America/Los_Angeles').format("h:mm a")),
            hearing_location: caseInfo.phone_hearing === Constants.CLIENT_PHONE_HEARING_APPROVED ? "Telephone Hearing" : caseInfo.trial_location,
            have_future_trial_date: (td > moment()), 
            have_trial_date: true
        };
    } else if (caseInfo.trial_date) {
        const td = moment(caseInfo.trial_date);
        return {
            hearing_date: td.tz('America/Los_Angeles').format("MMM D, YYYY"), 
            hearing_time: ("at " + td.tz('America/Los_Angeles').format("h:mm a")),
            hearing_location: caseInfo.phone_hearing === Constants.CLIENT_PHONE_HEARING_APPROVED ? "Telephone Hearing" : caseInfo.trial_location,
            have_future_trial_date: (td > moment()), 
            have_trial_date: true
        };
    } else {
        return {hearing_date: "Not scheduled yet", hearing_time: "", hearing_location: "", have_future_trial_date: false, have_trial_date: false};
    }
}

export function interpretPrepInfo(caseInfo) {
    if (caseInfo.prep_fsm_state == "cancelled") {
        return {prep_date: "Cancelled", prep_time: "", have_future_prep_date: false};
    } else if (caseInfo.prep_fsm_state == "withdrawn") {
        return {prep_date: "Cancelled", prep_time: "", have_future_prep_date: false};
    } else if (caseInfo.prep_meeting_date) {
        const pd = moment(caseInfo.prep_meeting_date);
        const ps = moment(caseInfo.prep_starts_at);
        const pe = moment(caseInfo.prep_ends_at);
        if (caseInfo.prep_starts_at) {
            return {
                prep_date: pd.tz('America/Los_Angeles').format("MMM D, YYYY"), 
                prep_time: `(We'll call you at ${ps.tz('America/Los_Angeles').format("h:mm a")})`,
                have_future_prep_date: (pd > moment())
            };
        } else {
            return {
                prep_date: pd.format("MMM D, YYYY"), 
                prep_time: "(We'll call you after 4pm)",
                have_future_prep_date: (pd > moment())
            };
        }
    } else {
        return {prep_date: "Not scheduled yet", prep_time: "", have_future_prep_date: false};
    }
}

export function interpretInterviewInfo(caseInfo) {
    if (caseInfo.interview_fsm_state == "cancelled") {
        return {interview_date: "Cancelled", interview_time: "", interview_location: "", have_future_interview_date: false, show_interview_date: true};
    } else if (caseInfo.interview_fsm_state == "withdrawn") {
        return {interview_date: "Withdrawn", interview_time: "", interview_location: "", have_future_interview_date: false, show_interview_date: true};
    } else if (caseInfo.interview_date) {
        const is = moment(caseInfo.interview_date);
        const ie = moment(caseInfo.interview_ends_at);
        return {
            interview_date: is.tz('America/Los_Angeles').format("MMM D, YYYY"), 
            interview_time: (`between ${is.tz('America/Los_Angeles').format("h:mm a")} and ${ie.tz('America/Los_Angeles').format("h:mm a")}`),
            interview_location: "(The EDD office will call you)",
            have_future_interview_date: (is > moment()),
            show_interview_date: true
        };
    } else {
        return {interview_date: "", interview_time: "", interview_location: "", have_future_interview_date: false, show_interview_date: false};
    }
}

export function shouldShowPrepAndHearingInformation(caseInfo) {
    return (caseInfo.case_type !== "Non-Appeal") && 
        (caseInfo.case_type !== "Second Level Appeal") && 
        (caseInfo.case_type !== "Second-Level Appeal") && 
        (caseInfo.case_type !== "Second Level Appeal - Unemployment");
}

export const docSamples = [
    {
        title: "Unemployment Notice of Determination",
        img: require("../assets/samples/unemployment_notice_of_determination.png"),
        docType: "EDD's Notice of Determination"
    },
    {
        title: "Unemployment Notice of Overpayment",
        img: require("../assets/samples/unemployment_notice_of_overpayment.png"),
        docType: "EDD's Notice of Overpayment"
    },
    {
        title: "Disability Notice of Determination",
        img: require("../assets/samples/disability_notice_of_determination.png"),
        docType: "EDD's Notice of Determination"
    },
    {
        title: "Notice of Benefits Continuation Form",
        img: require("../assets/samples/notice_of_benefits_continuation_form.png"),
        docType: "Benefits Continuation Form"
    },
    {
        title: "Disability Notice of Overpayment",
        img: require("../assets/samples/disability_notice_of_overpayment.png"),
        docType: "EDD's Notice of Overpayment"
    },
    {
        title: "Notice of Hearing",
        img: require("../assets/samples/notice_of_hearing.png"),
        docType: "Notice of Hearing"
    },
    {
        title: "Appeal Hearing Decision",
        img: require("../assets/samples/appeal_hearing_decision.png"),
        docType: "Appeal Hearing Decision"
    },
    {
        title: "Board Appeal Decision",
        img: require("../assets/samples/board_appeal_decision.png"),
        docType: "Board Appeal Decision"
    },
    {
        title: "Acknowledgement of Letter",
        img: require("../assets/samples/acknowledgement_of_letter.png"),
        docType: "Acknowledgement of Letter"
    },
    {
        title: "Appeal Acknowledgement",
        img: require("../assets/samples/appeal_acknowledgement.png"),
        docType: "Appeal Acknowledgement"
    },
    {
        title: "Other EDD Document",
        img: require("../assets/samples/placeholder_EDD.png"),
        docType: "Other EDD Document"
    },
    {
        title: "Work Related Document",
        img: require("../assets/samples/placeholder_work.png"),
        docType: "Work Related Document"
    },
    {
        title: "Health Related Document",
        img: require("../assets/samples/placeholder_health.png"),
        docType: "Health Related Document"
    },
    {
        title: "Other",
        img: require("../assets/samples/placeholder_other.png"),
        docType: "Other"
    },
];

export function findDocSample(docType) {
    if (!docType) return null;

    for (var i = 0; i < docSamples.length; ++i) {
        const sample = docSamples[i];
        if ((sample.docType.toUpperCase() === docType.toUpperCase()) || (sample.title.toUpperCase() === docType.toUpperCase())) {
            return sample;
        }
    }
    return null;
}

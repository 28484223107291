import React, { useState, useEffect } from "react";
import * as Analytics from "../common/analytics.js";
import API from "../common/api.js";
import { get } from "lodash";
import { Link } from "react-router-dom";
var classNames = require("classnames");

function EditContactInformation({ loading, caseInfo, requestRefreshCase }) {
    const [street, setStreet] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [zip, setZip] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [busy, setBusy] = useState(false);
    const [errMsg, setErrMsg] = useState(null);

    useEffect(() => {
        setStreet(get(caseInfo, "client.street", ""));
        setCity(get(caseInfo, "client.city", ""));
        setState(get(caseInfo, "client.province", ""));
        setZip(get(caseInfo, "client.postal_code", ""));
        setPhone(get(caseInfo, "client.phone", ""));
        setEmail(get(caseInfo, "client.email", ""));
    }, [caseInfo]);

    const isDirty =
        caseInfo !== null &&
        (get(caseInfo, "client.street") !== street ||
            get(caseInfo, "client.city") !== city ||
            get(caseInfo, "client.province") !== state ||
            get(caseInfo, "client.postal_code") !== zip ||
            get(caseInfo, "client.phone") !== phone ||
            get(caseInfo, "client.email") !== email);

    function formField(title, value, setter) {
        return (
            <div className="field six wide">
                <label>{title}</label>
                <input type="text" value={value} onChange={(e) => setter(e.target.value)} />
            </div>
        );
    }

    async function doSave() {
        Analytics.trackEvent("User", "Save contact info");
        setBusy(true);
        const newContactInfo = {
            street,
            city,
            province: state,
            postal_code: zip,
            phone,
            email,
        };
        const success = await API.updateContactInfo(caseInfo.client.id, newContactInfo);
        if (success) {
            await requestRefreshCase();
            setBusy(false);
            window.history.back();
        } else {
            setErrMsg("Something went wrong saving your changes. Please retry.");
            setBusy(false);
        }
    }

    const errMsgEl = errMsg !== null && (
        <div className="ui visible error message">
            <p>{errMsg}</p>
        </div>
    );

    let content = null;
    let closeButton = null;
    if (loading) {
        content = <div className="ui active inline text loader">Loading</div>;
    } else {
        content = (
            <div className="content">
                <div className="description">
                    <form className="ui form">
                        <div className="field six wide">
                            <label>Name</label>
                            <input type="text" value={get(caseInfo, "client.name", "")} readOnly disabled />
                        </div>
                        {formField("Street and number", street, setStreet)}
                        {formField("City", city, setCity)}
                        {formField("State", state, setState)}
                        {formField("Zip Code", zip, setZip)}
                        {formField("Phone number", phone, setPhone)}
                        {formField("Email", email, setEmail)}
                        <div className="field six wide">
                            <div
                                className={classNames("ui primary right labeled fluid icon button", {
                                    loading: busy,
                                    disabled: !isDirty,
                                })}
                                onClick={doSave}
                            >
                                Save
                            </div>
                        </div>
                        {errMsgEl}
                    </form>
                </div>
            </div>
        );
        closeButton = caseInfo && (
            <Link to={`/case/${encodeURI(caseInfo.case_number)}/details`}>
                <i className="ui right floated close icon" />
            </Link>
        );
    }

    return (
        <div className="ui container">
            <div className="ui fluid card" style={{ marginTop: "4em" }}>
                <div className="content">
                    {closeButton}
                    <div className="header">Edit Contact Information</div>
                </div>
                {content}
            </div>
        </div>
    );
}

export default EditContactInformation;

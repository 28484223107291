import React from 'react';
import { useABModal, useMessageModal } from '../common/useModal.js';
import '../common/style.css';
import API from '../common/api.js';
import { isMobileOnly } from "react-device-detect";
import AppStoreBadge from '../assets/app-store-badge.svg';
import PlayStoreBadge from '../assets/play-store-badge.svg';
import Logo from '../assets/icon.png';
var qs = require('qs');

function Home() {
  const urlParams = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  const [openSendSmsSuccess, sendSmsSuccessModal] = useMessageModal({
    title: "I Don't Know My Case PIN", 
    msg: "We've sent your case PIN via SMS.", 
    action: () => {}
  });
  const [openSendPinFailed, sendPinFailedModal] = useMessageModal({
    title: "I Don't Know My Case PIN", 
    msg: "Error: unable to send PIN for that case. Please check case number and try again.", 
    action: () => {}
  });
  const [openSendEmailSuccess, sendEmailSuccessModal] = useMessageModal({
    title: "I Don't Know My Case PIN", 
    msg: "We've sent your case PIN via email. It can take up to 5 minutes for this email to arrive in your email inbox.", 
    action: () => {}
  });
  const [openSmsOrEmailModal, smsOrEmailModal] = useABModal({
    title: "I Don't Know My Case PIN", 
    prompt: "We can send your case PIN via SMS to the phone number or email address we have on record for you.", 
    inputLabel: "Case Number",
    aButtonText: "Send Via SMS", 
    bButtonText: "Send Via Email", 
    aAction: async (caseNumber) => {
      const success = await API.remindCasePinSMS(caseNumber);
      if (success) {
        openSendSmsSuccess();
      } else {
        openSendPinFailed();
      }
    }, 
    bAction: async (caseNumber) => {
      const success = await API.remindCasePinEmail(caseNumber);
      if (success) {
        openSendEmailSuccess();
      } else {
        openSendPinFailed();
      }
    }
  });

  function onRemindPinClicked() {
    openSmsOrEmailModal();
  }

  const errmsg = (urlParams.success === "0") && (<div className="ui visible error message">{urlParams.msg}</div>);

  const loginForm = (
    <div className="ui middle aligned center aligned container grid">
      <div className="sixteen wide column" style={{maxWidth: 600}}>
        <form className="ui large form segment" style={{padding: 40}} action="/login" method="post">
          <h2 className="ui image header">
              <img src="/icon.png" className="image" alt="logo"/>
              <div className="content">Enter Your Case Number and PIN</div>
          </h2>

          <div className="ui raised segment">
            <div className="field">
              <div className="ui left icon input">
                <i className="address card icon"></i>
                <input type="text" name="case_number" placeholder="Enter your case number" required/>
              </div>
            </div>
            <div className="field">
              <div className="ui left icon input">
                <i className="lock icon"></i>
                <input type="password" name="case_pin" minLength="4" placeholder="Enter your 4-digit case PIN" required/>
              </div>
            </div>
            <button type="submit" className="ui fluid large primary submit button"><i className="lock open icon"></i> View Case</button>
            <div className="ui basic fluid red button" style={{marginTop: '2em'}} onClick={onRemindPinClicked}><i className="question icon"></i> I Don't Know My Case PIN</div>
          </div>

          {errmsg}

        </form>
      </div>

      {sendSmsSuccessModal}
      {sendPinFailedModal}
      {sendEmailSuccessModal}
      {smsOrEmailModal}
    </div>
  );

  const appStoreInfo = (
    <div className="hcenter-vcenter">
      <img src={Logo} width="60" height="60" alt="logo" style={{position: 'absolute', top: 20, left: 0, right: 0, margin: 'auto'}}/>
      <h3 className="mb-4">Please Download Our Mobile App</h3>
      <a href="https://apps.apple.com/app/apple-store/id1461675991"><img src={AppStoreBadge} width="180" height="60" className="mb-4" alt="app store button"/></a>
      <a href="https://play.google.com/store/apps/details?id=com.pershingsquarelaw.caseaccess"><img src={PlayStoreBadge} width="180" height="60" alt="play store button"/></a>
    </div>
  );

  const containerStyle = {
      height: '100vh', 
      display: 'flex', 
      backgroundColor: "rgb(230, 237, 241)"
  };

  return (
      <div style={containerStyle}>
        {isMobileOnly ? appStoreInfo : loginForm} 
      </div>
  );
}

export default Home;

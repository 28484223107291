import React from 'react';
import { Link } from "react-router-dom";
import * as Constants from '../common/webconstants.js';
var classNames = require('classnames');


export default function Navigation({caseNumber, activeTab}) {
  return (
    <div className="ui borderless menu">
      <div className="ui container">
        <Link to={`/`} className="header item ml-16" style={{marginLeft: -16}}>
          <img className="logo" src="/icon.png" alt="logo" style={{position: 'absolute', top: 10, width: 80}}/>
          <span style={{marginLeft: 100}}>CASE {caseNumber.toUpperCase()}</span>
        </Link>
        <Link to={`/case/${encodeURI(caseNumber)}/details`} className={classNames("item", {active: activeTab === Constants.DETAILS_TAB})}>DETAILS</Link>
        <Link to={`/case/${encodeURI(caseNumber)}/status`} className={classNames("item", {active: activeTab === Constants.STATUS_TAB})}>STATUS</Link>
        <Link to={`/case/${encodeURI(caseNumber)}/documents`} className={classNames("item", {active: activeTab === Constants.DOCUMENTS_TAB})}>DOCUMENTS</Link>
        <div className="right menu">
          <a href="/" className="ui borderless item">LOG OUT</a>
        </div>
      </div>
    </div>
  );
}

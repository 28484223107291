import React, { useState } from 'react';
import Modal from 'react-modal';
import './style.css';
var classNames = require('classnames');


Modal.setAppElement('#root');

const modalStyles = {
    content : {
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '-50%',
      transform             : 'translate(-50%, -50%)',
      width                 : '500px'
    }
};

export function useRequestTextInputModal({title, prompt, okButtonText, action}) {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [text, setText] = useState("");
    const [busy, setBusy] = useState(false);

    async function accept() {
        setBusy(true);
        await action(text);
        setBusy(false);
        setModalIsOpen(false);
    }

    function open() {
        setText("");
        setModalIsOpen(true);
    }

    const modal = (
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={() => setModalIsOpen(false)}
            contentLabel=""
            style={modalStyles}
            className="ui active modal"
            overlayClassName="modal-overlay"
            >
                <div className="header">{title}</div>
                <div className="content">
                    <div className="description">
                        <p>{prompt}</p>
                        <div className="ui fluid input"><input type="text" value={text} onChange={(e) => setText(e.target.value)}/></div>
                    </div>
                </div>
                <div className="actions">
                    <div className="ui black basic button" onClick={() => setModalIsOpen(false)}>Cancel</div>
                    <div className={classNames("ui positive right labeled icon button", {loading: busy})} onClick={accept}>{okButtonText} <i className="checkmark icon"/></div>
                </div>
        </Modal>
    );

    return [open, modal];
}


export function useYesNoModal({title, prompt, yesAction, noAction}) {
    const [modalIsOpen, setModalIsOpen] = useState(false);

    function onYesPressed() {
        setModalIsOpen(false);
        yesAction();
    }

    function onNoPressed() {
        setModalIsOpen(false);
        noAction();
    }

    function open() {
        setModalIsOpen(true);
    }

    const modal = (
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={() => setModalIsOpen(false)}
            contentLabel=""
            style={modalStyles}
            className="ui active modal"
            overlayClassName="modal-overlay"
            >
                <div className="header">{title}</div>
                <div className="content">
                    <div className="description">
                        <p>{prompt}</p>
                    </div>
                </div>
                <div className="actions">
                    <div className="ui red right labeled icon button" onClick={onNoPressed}>No <i className="x icon"/></div>
                    <div className="ui positive right labeled icon button" onClick={onYesPressed}>Yes <i className="checkmark icon"/></div>
                </div>
        </Modal>
    );

    return [open, modal];
}


export function useABModal({title, prompt, inputLabel, aButtonText, bButtonText, aAction, bAction}) {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [text, setText] = useState("");
    const [openRequiredFieldModal, requiredFieldModal] = useMessageModal({
        title: "Input Error", 
        msg: `The ${inputLabel} field is required!`, 
        action: () => {}
    });

    function onAPressed() {
        if (text === "") {
            openRequiredFieldModal();
        } else {
            setModalIsOpen(false);
            aAction(text);
        }
    }

    function onBPressed() {
        if (text === "") {
            openRequiredFieldModal();
        } else {
            setModalIsOpen(false);
            bAction(text);
        }
    }

    function open() {
        setText("");
        setModalIsOpen(true);
    }

    const modal = (
        <>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={() => setModalIsOpen(false)}
                contentLabel=""
                style={modalStyles}
                className="ui active modal"
                overlayClassName="modal-overlay"
                >
                    <div className="header">{title}</div>
                    <div className="content">
                        <div className="description">
                            <p>{prompt}</p>
                            <div className="ui form">
                                <div className="field">
                                    <label>{inputLabel}</label>
                                    <input type="text" value={text} placeholder={`${inputLabel}...`} onChange={(e) => setText(e.target.value)}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="actions" style={{paddingRight: 21}}>
                        <div className="ui primary button" onClick={onAPressed}>{aButtonText}</div>
                        <div className="ui primary button" onClick={onBPressed}>{bButtonText}</div>
                    </div>
            </Modal>
            {requiredFieldModal}
        </>
    );

    return [open, modal];
}


export function useMessageModal({title, msg, action}) {
    const [modalIsOpen, setModalIsOpen] = useState(false);

    function onOKPressed() {
        setModalIsOpen(false);
        action();
    }

    function open() {
        setModalIsOpen(true);
    }

    const modal = (
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={() => setModalIsOpen(false)}
            contentLabel=""
            style={modalStyles}
            className="ui active modal"
            overlayClassName="modal-overlay"
            >
                <div className="header">{title}</div>
                <div className="content">
                    <div className="description">
                        <p>{msg}</p>
                    </div>
                </div>
                <div className="actions">
                    <div className="ui positive right labeled icon button" onClick={onOKPressed}>OK <i className="checkmark icon"/></div>
                </div>
        </Modal>
    );

    return [open, modal];
}


export function useErrorModal({title, msg, requestRetry}) {
    const [modalIsOpen, setModalIsOpen] = useState(false);

    function onRetryPressed() {
        setModalIsOpen(false);
        requestRetry();
    }

    function open() {
        setModalIsOpen(true);
    }

    const modal = (
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={() => setModalIsOpen(false)}
            contentLabel=""
            style={modalStyles}
            className="ui active modal"
            overlayClassName="modal-overlay"
            >
                <div className="header">{title}</div>
                <div className="content">
                    <div className="description">
                        <div className="ui error message">
                            <div className="header">Error</div>
                            <p>{msg}</p>
                        </div>
                    </div>
                </div>
                <div className="actions">
                    <div className="ui black basic button" onClick={() => setModalIsOpen(false)}>Cancel</div>
                    <div className="ui negative right labeled icon button" onClick={onRetryPressed}>Try Again <i className="exclamation circle icon"/></div>
                </div>
        </Modal>
    );

    return [open, modal];
}


export function useCustomModal({title, contentElement, resetContentElement, okButtonText, action, styles}) {
    const [modalIsOpen, setModalIsOpen] = useState(false);

    function accept() {
        setModalIsOpen(false);
        action();
    }

    function open() {
        resetContentElement();
        setModalIsOpen(true);
    }

    const mergedStyles = {content: {...modalStyles.content, ...styles}};
    const modal = (
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={() => setModalIsOpen(false)}
            contentLabel=""
            style={mergedStyles}
            className="ui active modal"
            overlayClassName="modal-overlay"
            >
                <div className="header">{title}</div>
                <div className="content">
                    {contentElement}
                </div>
                <div className="actions">
                    <div className="ui black basic button" onClick={() => setModalIsOpen(false)}>Cancel</div>
                    <div className="ui positive right labeled icon button" onClick={accept}>{okButtonText} <i className="checkmark icon"/></div>
                </div>
        </Modal>
    );

    return [open, modal];
}
import React from "react";
import CaseDetails from "../components/casedetails.js";
import CaseStatus from "../components/casestatus.js";
import CaseDocuments from "../components/casedocuments.js";
import * as Constants from "../common/webconstants.js";

function Case({
    activeTab,
    caseNumber,
    loading,
    refreshing,
    caseInfo,
    caseHistory,
    caseDocuments,
    errMsg,
    requestRefreshCase,
}) {
    const refreshSpinner = refreshing && <div className="ui active mini inline loader" />;

    let tab = null;
    if (errMsg) {
        tab = (
            <div className="ui container">
                <div className="ui error message">
                    {errMsg}
                    <br />
                    <br />
                    <a className="ui primary button" href="/">
                        Log In
                    </a>
                </div>
            </div>
        );
    } else if (loading) {
        tab = <div className="ui container"><div className="ui active inline text loader">Loading</div></div>;
    } else if (activeTab === Constants.DETAILS_TAB) {
        tab = caseInfo && (
            <CaseDetails caseNumber={caseNumber} caseInfo={caseInfo} requestRefreshCase={requestRefreshCase} />
        );
    } else if (activeTab === Constants.STATUS_TAB) {
        tab = caseInfo && caseHistory && (            
            <CaseStatus caseNumber={caseNumber} caseInfo={caseInfo} caseHistory={caseHistory} />
        );
    } else if (activeTab === Constants.DOCUMENTS_TAB) {
        tab = caseInfo && caseDocuments && (
            <div style={{ background: "white", paddingTop: 20, paddingBottom: 40 }}>
                <div className="ui container">
                    <CaseDocuments caseNumber={caseNumber} caseInfo={caseInfo} caseDocuments={caseDocuments} />
                </div>
            </div>
        );
    }

    const { practice_area, case_type } = caseInfo || {};

    return (
        <div>
            <div>
                <div className="ui container">
                    <h1 className="ui header" style={{ paddingTop: 40 }}>
                        Case # {caseNumber} {refreshSpinner}
                    </h1>
                    <div style={{ paddingBottom: 40 }}>
                        <p className="mb-1">
                            {practice_area} | {case_type}
                        </p>
                    </div>
                </div>
                {tab}
            </div>
        </div>
    );
}

export default Case;

import React, { useState } from 'react';
import { interpretHearingInfo, interpretPrepInfo, interpretInterviewInfo, shouldShowPrepAndHearingInformation } from '../common/interpretcaseinfo.js';
import '../common/style.css';
var classNames = require('classnames');
var moment = require('moment');
require("../common/moment-timezone-with-data.js");


function EventItem({title, dateStr, detail, startExpanded}) {
    const [expanded, setExpanded] = useState(startExpanded);

    function toggle() {
        setExpanded(!expanded);
    }

    return (
        <>
            <div className={classNames("row p-0", {active: expanded})} onClick={toggle}>
                <div className={classNames("four wide column title", {active: expanded})}><i className="dropdown icon"/> {title}</div>
                <div className={classNames("twelve wide column title", {active: expanded})}>{dateStr}</div>
            </div>
            <div className={classNames("row p-0", {active: expanded})}>
                <div className={classNames("sixteen wide column content", {active: expanded})}>
                    <p className={classNames("ui info message", {hidden: !expanded, visible: expanded})}>{detail}</p>
                </div>
            </div>
        </>
    );
}

function HistoryItem({timestamp, title, detail, startExpanded}) {
    const timestampFormatted = moment(timestamp).format("MMM D YYYY");
    const [expanded, setExpanded] = useState(startExpanded);

    function toggle() {
        setExpanded(!expanded);
    }
        
    return (
        <>
            <div className="row p-0" onClick={toggle}>
                <div className={classNames("four wide column title", {active: expanded})}><i className="dropdown icon"/> {timestampFormatted}</div>
                <div className={classNames("twelve wide column title", {active: expanded})}>{title}</div>
            </div>
            <div className="row p-0">
                <div className={classNames("sixteen wide column content", {active: expanded})}>
                    <p className={classNames("ui info message", {hidden: !expanded, visible: expanded})}>{detail}</p>
                </div>
            </div>
        </>
    );
}


function CaseStatus({caseInfo, caseHistory}) {
    if (!caseInfo) return null;

    const { hearing_date, hearing_time, have_future_trial_date } = interpretHearingInfo(caseInfo);
    const { prep_date, have_future_prep_date } = interpretPrepInfo(caseInfo);
    const { interview_date, interview_time, show_interview_date, have_future_hearing_date } = interpretInterviewInfo(caseInfo);

    const hearingExpl = "Please arrive at the hearing location 20 minutes before the scheduled time. You will need to check in at the window and receive your file. The attorney will meet with you in person or by phone 15 minutes before the hearing begins for a quick overview of your case. Appropriate attire is the same as you would wear to work, suit and tie are not necessary. Be respectful to the staff and address the judge as Your Honor, Sir or Madam. We won’t get a decision immediately. Rather, the judge will send a decision in the mail shortly after the hearing.";
    const prepExpl = "Prior to your hearing we will hold a telephone meeting to discuss your case. We usually hold the prep meetings after 4:00pm the day before the hearing. If you wish to have the call during a specific time please submit a reschedule request in the Details tab.";
    const interviewExpl = "The EDD determined it needs to ask you some questions. See the Details tab for when they will call. Make sure you are available to take their call within those hours and report back to us if there’s a problem with the interview.";

    const hearing_date_str = `${hearing_date} ${hearing_time}`;
    const interview_date_str = `${interview_date} ${interview_time}`;
    const should_show_important_events = shouldShowPrepAndHearingInformation(caseInfo);
    const important_events_section = (
        <div style={{marginBottom: 40}}>
            <h2 className="ui header">Important Events</h2>
            <div className="ui accordion grid" style={{marginTop: '1em'}}>
                <EventItem title="Prep Meeting" dateStr={prep_date} detail={prepExpl} startExpanded={have_future_prep_date}/>
                <EventItem title="Hearing" dateStr={hearing_date_str} detail={hearingExpl} startExpanded={have_future_trial_date}/>
                {show_interview_date && <EventItem title="EDD Phone Interview" dateStr={interview_date_str} detail={interviewExpl} startExpanded={have_future_hearing_date}/>}
            </div>
        </div>
    );

    const historyRows = caseHistory.map((item, inx) => 
        <HistoryItem
            key={inx}
            title={item.payload.title}
            detail={item.payload.detail}
            timestamp={item.payload.date || (item.timestamp*1000)}
            createdAt={item.timestamp}
            startExpanded={inx === 0}/>
    );

    return (
        <div>
            <div className="white strip">
                <div className="ui container">
                    {should_show_important_events && important_events_section}
                </div>
            </div>

            <div className="ui container strip">
                <h2 className="ui header">Case History</h2>
                <div className="ui accordion grid" style={{marginTop: '1em'}}>
                    {historyRows}
                </div>
            </div>
        </div>
    );
}

export default CaseStatus;

import React from 'react';
import { interpretHearingInfo, interpretPrepInfo, interpretInterviewInfo, shouldShowPrepAndHearingInformation } from '../common/interpretcaseinfo.js';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import * as Constants from '../common/constants.js';
import API from '../common/api.js';
import useGotHearingDateDialog from './gothearingdatedialog.js';
import useReschedulePrepDialog from './rescheduleprepdialog.js';
import useRescheduleHearingDialog from './reschedulehearingdialog.js';
import useRescheduleInterviewDialog from './rescheduleinterviewdialog.js';
import { Link } from "react-router-dom";
import '../common/style.css';


function CaseDetails({caseInfo, requestRefreshCase}) {
    const [openReschedulePrepModal, reschedulePrepModal] = useReschedulePrepDialog({caseInfo});
    const [openRescheduleInterviewModal, rescheduleInterviewModal] = useRescheduleInterviewDialog({caseInfo});
    const [openRescheduleHearingModal, rescheduleHearingModal] = useRescheduleHearingDialog({caseInfo});
    const [openIGotHearingDateModal, gotHearingDateModal] = useGotHearingDateDialog({caseInfo, requestRefreshCase});

    function onAddToCalendarPressed() {
        const url = API.generateICS(caseInfo.trial_date, caseInfo.trial_ends_at, `Hearing ${caseInfo.practice_area} - ${caseInfo.case_type} case (${caseInfo.case_number})`, caseInfo.trial_location);
        window.open(url);
    }

    function onAddInterviewToCalendarPressed() {
        const url = API.generateICS(caseInfo.interview_date, caseInfo.interview_ends_at, `EDD Phone Interview ${caseInfo.practice_area} - ${caseInfo.case_type} case (${caseInfo.case_number})`, "The EDD office will call you");
        window.open(url);
    }
    
    const phone = parsePhoneNumberFromString(caseInfo.client.phone, 'US').formatNational();
    const city_state_and_zip = [caseInfo.client.city, caseInfo.client.province, caseInfo.client.postal_code].join(" ");
    const { hearing_date, hearing_time, hearing_location, have_future_trial_date, have_trial_date } = interpretHearingInfo(caseInfo);
    const { prep_date, prep_time, have_future_prep_date } = interpretPrepInfo(caseInfo);
    const { interview_date, interview_time, interview_location, have_future_interview_date, show_interview_date } = interpretInterviewInfo(caseInfo);

    const prep_button = have_future_prep_date && <button className="ui tiny basic primary button" onClick={openReschedulePrepModal}>Reschedule</button>;

    const hearing_location_line = hearing_location ? <div>{hearing_location}</div> : null;
    const reschedule_hearing_button = <button className="ui tiny basic primary button" onClick={openRescheduleHearingModal}>Reschedule</button>;
    const i_got_hearing_button = (
        <div>
            <button className="ui tiny basic primary button" onClick={openIGotHearingDateModal}>I Got A Hearing Date</button>
        </div>
    );
    const add_to_calendar_button = <button className="ui tiny basic primary button" onClick={onAddToCalendarPressed}>Add To Calendar</button>;
    const future_hearing_buttons = (
        <div>
            {reschedule_hearing_button}
            <span style={{width: 4, display: 'inline-block'}}/>
            {add_to_calendar_button}
        </div>
    );
    const phone_hearing = 
        have_future_trial_date && (caseInfo.phone_hearing === Constants.CLIENT_PHONE_HEARING_REQUESTED) ? <span>(Your request to attend by phone is pending)</span> :
        have_future_trial_date && (caseInfo.phone_hearing === Constants.CLIENT_PHONE_HEARING_DENIED) ? <span>(Your request to attend by phone was denied)</span> :
        null;

    const interview_location_line = interview_location ? <span>{interview_location}</span> : null;
    const reschedule_interview_button = <button className="ui tiny basic primary button" onClick={openRescheduleInterviewModal}>Reschedule</button>;
    const add_interview_to_calendar_button = <button className="ui tiny basic primary button" onClick={onAddInterviewToCalendarPressed}>Add To Calendar</button>;
    const future_interview_buttons = (
        <div>
            {reschedule_interview_button}
            <span style={{width: 4, display: 'inline-block'}}/>
            {add_interview_to_calendar_button}
        </div>
    );

    const show_hearing_information = shouldShowPrepAndHearingInformation(caseInfo);
    const hearing_information_section = (
        <div className="mt-12">
            <h2 className="ui header">Hearing Information</h2>
            <table className="ui very basic table">
                <tbody>
                    <tr>
                        <td className="four wide align-top font-bold">Prep meeting</td>
                        <td>
                            <p className="mb-1">{prep_date}</p>
                            {(prep_time !== "") && <p  className="mb-1">{prep_time}</p>}
                            <div className="mt-4"/>
                            {prep_button}
                        </td>
                    </tr>
                    <tr>
                        <td className="four wide align-top font-bold">Hearing</td>
                        <td>
                            <p className="mb-1">{hearing_date} {hearing_time}</p>
                            {hearing_location_line}
                            {phone_hearing}
                            <div className="mt-4"/>
                            {have_future_trial_date && future_hearing_buttons}
                            {!have_trial_date && i_got_hearing_button}
                        </td>
                    </tr>
                    {
                        show_interview_date &&
                        (<tr>
                            <td className="four wide align-top font-bold">EDD Interview</td>
                            <td>
                                <p className="mb-1">{interview_date} {interview_time}</p>
                                {interview_location_line}
                                <div className="mt-4"/>
                                {have_future_interview_date && future_interview_buttons}
                            </td>
                        </tr>)
                    }
                </tbody>
            </table>
        </div>
    );

    return (
        <div>
            <div className="strip white">
                <div className="ui container">
                    {show_hearing_information && hearing_information_section}
                </div>
            </div>

            <div className="ui container strip">
                <div className="mt-12">
                    <h2 className="ui header">Your Contact Information</h2>
                    <p className="mb-1">{caseInfo.client.name}</p>
                    <p className="mb-1">{caseInfo.client.street}</p>
                    <p className="mb-1">{city_state_and_zip}</p>
                    <p className="mb-1">{phone}</p>
                    <p className="mb-1">{caseInfo.client.email}</p>
                    <Link to={`/case/${encodeURI(caseInfo.case_number)}/editcontactinformation`} className="ui basic primary button" style={{marginTop: "1em"}}>Edit Contact Information</Link>
                </div>
            </div>

            {reschedulePrepModal}
            {rescheduleHearingModal}
            {gotHearingDateModal}
            {rescheduleInterviewModal}
        </div>
    );
}

export default CaseDetails;

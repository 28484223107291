import React, { useState } from 'react';
import * as Analytics from '../common/analytics.js';
import API from '../common/api.js';
import Dropdown from 'react-dropdown';
import Modal from 'react-modal';
import '../common/style.css';
import 'react-dropdown/style.css'
var classNames = require('classnames');
var moment = require('moment');
require("../common/moment-timezone-with-data.js");


export default function useGotHearingDateDialog({caseInfo, requestRefreshCase}) {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [completed, setCompleted] = useState(false);
    const [failed, setFailed] = useState(false);
    const [location, setLocation] = useState("");
    const [judge, setJudge] = useState("");
    const [month, setMonth] = useState(null);
    const [day, setDay] = useState(null);
    const [year, setYear] = useState(null);
    const [time, setTime] = useState(null);

    const invalidDate = 
        (month !== null) &&
        (day !== null) &&
        (year !== null) &&
        (!moment([year.value, month.value - 1, day.value]).isValid());

    const submittable = 
        (judge.length > 0) &&
        (location.length > 0) &&
        (month !== null) &&
        (day !== null) &&
        (year !== null) &&
        (time !== null) &&
        (invalidDate === false);

    const monthOptions = [
        {value: 1, label: 'January'},
        {value: 2, label: 'February'},
        {value: 3, label: 'March'},
        {value: 4, label: 'April'},
        {value: 5, label: 'May'},
        {value: 6, label: 'June'},
        {value: 7, label: 'July'},
        {value: 8, label: 'August'},
        {value: 9, label: 'September'},
        {value: 10, label: 'October'},
        {value: 11, label: 'November'},
        {value: 12, label: 'December'}        
    ];
    const dayOptions = [
        1, 2, 3, 4, 5, 6, 7, 8, 9,
        10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
        20, 21, 22, 23, 24, 25, 26, 27, 28, 29,
        30, 31
    ];
    const currYear = new Date().getFullYear();
    const yearOptions = [currYear, currYear + 1, currYear + 2];
    const timeOptions = [
        {value: "07:00", label: "7:00 AM"},
        {value: "07:30", label: "7:30 AM"},
        {value: "08:00", label: "8:00 AM"},
        {value: "08:30", label: "8:30 AM"},
        {value: "09:00", label: "9:00 AM"},
        {value: "09:30", label: "9:30 AM"},
        {value: "10:00", label: "10:00 AM"},
        {value: "10:30", label: "10:30 AM"},
        {value: "11:00", label: "11:00 AM"},
        {value: "11:30", label: "11:30 AM"},
        {value: "12:00", label: "12:00 PM"},
        {value: "12:30", label: "12:30 PM"},
        {value: "13:00", label: "1:00 PM"},
        {value: "13:30", label: "1:30 PM"},
        {value: "14:00", label: "2:00 PM"},
        {value: "14:30", label: "2:30 PM"},
        {value: "15:00", label: "3:00 PM"},
        {value: "15:30", label: "3:30 PM"},
        {value: "16:00", label: "4:00 PM"},
        {value: "16:30", label: "4:30 PM"},
        {value: "17:00", label: "5:00 PM"},
        {value: "17:30", label: "5:30 PM"},
        {value: "18:00", label: "6:00 PM"},
        {value: "18:30", label: "6:30 PM"},
        {value: "19:00", label: "7:00 PM"},
        {value: "19:30", label: "7:30 PM"},
        {value: "20:00", label: "8:00 PM"},
        {value: "20:30", label: "8:30 PM"},
    ];

    function open() {
        setSubmitting(false);
        setCompleted(false);
        setLocation("");
        setJudge("");
        setYear(null);
        setMonth(null);
        setDay(null);
        setTime(null);
        setModalIsOpen(true);
    }

    function accept() {
        setSubmitting(true);
        Analytics.trackEvent("User", "Reported hearing date");
        
        setTimeout(async () => {
            if (await API.sendHearingInfo(caseInfo.matter_id, year.value, month.value, day.value, time.value, location, judge)) {
                if (requestRefreshCase) {
                    await requestRefreshCase();
                }
                setSubmitting(false);
                setCompleted(true);
            } else {
                setSubmitting(false);
                setFailed(true);
            }
        }, 100);
    }

    const formContent = (
        <div className="content">
            <div className="description">
                <p>Please enter the date and time of the hearing, as well as the location and the judge's name.</p>
                <form className="ui equal width form">
                    <div className="fields">
                        <div className={classNames("field", {error: invalidDate})}>
                            <label>Month</label>
                            <Dropdown options={monthOptions} onChange={(option) => setMonth(option)} value={month} placeholder="Select" />
                        </div>
                        <div className={classNames("field", {error: invalidDate})}>
                            <label>Day</label>
                            <Dropdown options={dayOptions} onChange={(option) => setDay(option)} value={day} placeholder="Select" />
                        </div>
                        <div className={classNames("field", {error: invalidDate})}>
                            <label>Year</label>
                            <Dropdown options={yearOptions} onChange={(option) => setYear(option)} value={year} placeholder="Select" />
                        </div>
                        <div className="field">
                            <label>Time</label>
                            <Dropdown options={timeOptions} onChange={(option) => setTime(option)} value={time} placeholder="Select" />
                        </div>
                    </div>
                    <div className="field">
                        <label>Location</label>
                        <input type="text" placeholder="Enter location..." value={location} onChange={(e) => setLocation(e.target.value)}/>
                    </div>
                    <div className="field">
                        <label>Judge's name</label>
                        <input type="text" placeholder="Enter judge's name..." value={judge} onChange={(e) => setJudge(e.target.value)}/>
                    </div>
                </form>
            </div>
        </div>
    );

    const formActions = (
        <div className="actions">
            <div className="ui black basic button" onClick={() => setModalIsOpen(false)}>Cancel</div>
            <div className={classNames("ui positive right labeled icon button", {loading: submitting, disabled: !submittable})} onClick={accept}>Send Hearing Info <i className="checkmark icon"/></div>
        </div>
    );

    const completedContent = (
        <div className="content">
            <div className="description">
                <p>We've received the hearing information and will call you one business day before the hearing to prepare.</p>
            </div>
        </div>
    );

    const completedActions = (
        <div className="actions">
            <div className="ui positive right labeled icon button" onClick={() => setModalIsOpen(false)}>OK <i className="checkmark icon"/></div>
        </div>
    );

    const failedContent = (
        <div className="content">
            <div className="description">
                <div class="ui error message">
                    <div class="header">Error</div>
                    <p>Something went wrong with processing the hearing information you provided. Please retry.</p>
                </div>
            </div>
        </div>
    );

    const failedActions = (
        <div className="actions">
            <div className="ui black basic button" onClick={() => setModalIsOpen(false)}>Cancel</div>
            <div className="ui negative right labeled icon button" onClick={() => {setFailed(false); setSubmitting(false);}}>Try Again <i className="exclamation circle icon"/></div>
        </div>
    );

    let content = formContent;
    let actions = formActions;
    if (completed) {
        content = completedContent;
        actions = completedActions;
    } else if (failed) {
        content = failedContent;
        actions = failedActions;
    }

    const modalStyles = {
        content : {
          top                   : '50%',
          left                  : '50%',
          right                 : 'auto',
          bottom                : 'auto',
          marginRight           : '-50%',
          transform             : 'translate(-50%, -50%)',
          width                 : '650px'
        }
    };
    const modal = (
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={() => setModalIsOpen(false)}
            contentLabel=""
            style={modalStyles}
            className="ui active modal"
            overlayClassName="modal-overlay"
            >
                <div className="header">I Got A Hearing Date</div>
                {content}
                {actions}
        </Modal>
    );

    return [open, modal];
}
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Link } from "react-router-dom";
import * as Analytics from '../common/analytics.js';
import API from '../common/api.js';


function ViewDocument({ caseNumber, documentID, caseInfo }) {
    const [loading, setLoading] = useState(true);
    const [title, setTitle] = useState(null);
    const [errMsg, setErrMsg] = useState(null);

    const didCancel = useRef(false);
    const fetchDocumentInfo = useCallback(async () => {
        if (caseInfo) {
            const docInfo = await API.getDocumentInfo(caseInfo.matter_id, documentID);
            if (docInfo) {
                if (!didCancel.current) { // Ignore if we started fetching something else
                    setTitle(docInfo.title);
                    setLoading(false);
                    Analytics.trackEvent("User", "Open inbox document");
                }
            } else {
                setErrMsg("Error: invalid document ID.");
                setLoading(false);
            }
        }
    }, [caseInfo, documentID]);

    useEffect(() => {
        didCancel.current = false;
        fetchDocumentInfo();
        return () => { didCancel.current = true; }; // Remember if we start fetching something else
    }, [caseInfo, fetchDocumentInfo]);

    let content = null;
    if (errMsg) {
        content = (
            <div className="ui container">
                <div className="ui error message">{errMsg}<br/><br/><a className="ui primary button" href="/">Log In</a></div>
            </div>
        );
    } else if (loading) {
        content = (
            <div className="ui container">
                <div className="ui active inline text loader">Loading</div>
            </div>
        );
    } else {
        content = (
            <>
                <h4 className="ui header">{title}</h4>
                <iframe src={`/viewdocument/${documentID}`} title="Document viewer" style={{height: '100%', width: '100%', flex: 1}}/>
            </>
        );
    }

    return (
        <div className="ui container" style={{position: 'relative', display: 'flex', flexDirection: 'column', flex: 1}}>
            <Link to={`/case/${encodeURI(caseNumber)}/documents`} style={{position: 'absolute', top: 14, right: 0}} className="ui small labeled primary icon button"><i className="list ul icon"/> Back to document list</Link>
            {content}
        </div>
    );
}
  
export default ViewDocument;

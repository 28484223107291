import React from 'react';
import '../common/style.css';
import { Link } from "react-router-dom";
import { orderBy } from "lodash";
var moment = require('moment');
require("../common/moment-timezone-with-data.js");


function CaseDocuments({caseInfo, caseDocuments}) {
    if (!caseInfo) return null;

    const rows = orderBy(caseDocuments, ['timestamp'], ['desc']).map((doc) => {
        const {timestamp, title, document_id} = doc;
        const timestampFormatted = moment(timestamp * 1000).format("MMM D YYYY");
        return (
            <tr key={document_id}>
                <td className="collapsing">{timestampFormatted}</td>
                <td className=""><Link to={`/case/${encodeURI(caseInfo.case_number)}/document/${document_id}`}>{title}</Link></td>
            </tr>
        );
    })

    return (
        <div style={{position: 'relative'}}>
            <table className="ui very basic striped table">
                <thead>
                    <tr>
                        <th className="collapsing">Date</th>
                        <th className="">Title</th>
                    </tr>
                </thead>
                <tbody>
                    {rows}
                </tbody>
            </table>
            <Link to={`/case/${caseInfo.case_number}/upload`} className="ui basic primary labeled icon button" style={{position: 'absolute', top: 0, right: 0}}><i className="plus icon"/>Upload New Document</Link>
        </div>
    );
}

export default CaseDocuments;
